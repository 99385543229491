<template>
  <div class="outer-page">
    <div>
      <article-info></article-info>
    </div>
    <!-- <div class="imgs-position">
      <img src="imgs/backgroundImg/empty.png" alt="" />
    </div> -->

    <!-- <Modal v-model="imgModal" title="产业架构" fullscreen footer-hide> -->
    <!-- <div class="meteorologyShow" @mouseout="() => (isDrag = false)">
        <div
          class="imgAction"
          @mousedown="initDrag"
          @mouseup="
            () => {
              isDrag = false;
            }
          "
          @mousewheel="funWheel"
          ref="actionImg"
          id="actionImg"
        >
          <img :src="carouselImg.icon" alt="" />
          <div class="mask"></div>
        </div>
        <div class="toolbar" title="复位" @click="imgBack">
          <Icon type="md-refresh" />
        </div>
      </div> -->
    <viewer :images="[carouselImg.icon]" ref="viewer" v-if="carouselImg.icon">
      <img :src="carouselImg.icon" alt="" />
    </viewer>
    <!-- </Modal> -->
  </div>
</template>

<script>
import articleInfo from "@components/articleInfo";
import { component as Viewer } from "v-viewer";
export default {
  name: "",
  components: {
    articleInfo,
    Viewer,
  },
  computed: {
    locationHref() {
      return window.location.href;
    },
  },
  data() {
    return {
      isShow: false,

      imgModal: false,
      carouselImg: {},

      x: 0,
      y: 0,
      nTX: 0,
      ntY: 0,
      oDragObj: "",
      isDrag: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(n) {
        if (n.path === "/74") {
          this.getCarouse();
        }
      },
    },
  },
  methods: {
    changeHref() {
      // let index = this.locationHref.indexOf("?");
      // let oHref = this.locationHref;
      // if (index !== -1) {
      //   let num = oHref.split("?")[1];
      //   if (num == "JTJG") {
      //     this.isShow = true;
      //   } else {
      //     this.isShow = false;
      //   }
      // } else {
      //   this.isShow = false;
      // }
    },

    getCarouse() {
      this.$post(this.$api.IMG_MANAGE.LIST, {
        imgType: "6",
        type: "2",
      }).then((res) => {
        this.carouselImg = res.list[0];
        this.imgModal = true;
        this.$nextTick(() => {
          this.$refs.viewer.$viewer.show();
        });
      });
    },
    // 复位图片
    imgBack() {
      // this.$refs.actionImg.style =
      //   "top: 0;left:0;width: 100%;height: 100%; transition: all .2s ease;";
      let img = document.getElementById("actionImg");
      // img.style = "top: 0;left:0;width: 100%;height: 100%; transition: all .2s ease;";
      img.style.top = 0;
      img.style.left = 0;
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.transition = "all .2s ease";
    },

    funWheel(ev) {
      this.$refs.actionImg.style.transition = "";
      let oEvent = ev || window.event;
      let down = true;
      if (oEvent.detail) {
        down = oEvent.detail > 0;
      } else {
        down = oEvent.wheelDelta < 0;
      }
      this.wheelAction.call(this.$refs.actionImg, down, oEvent);
      if (oEvent.preventDefault) {
        oEvent.preventDefault();
      }
      return false;
    },

    wheelAction(down, oEvent) {
      let oldWidth = this.$refs.actionImg.offsetWidth;
      let oldHeight = this.$refs.actionImg.offsetHeight;
      let oldLeft = this.$refs.actionImg.offsetLeft;
      let oldTop = this.$refs.actionImg.offsetTop;
      let scaleX = (oEvent.clientX - oldLeft) / oldWidth;
      let scaleY = (oEvent.clientY - oldTop) / oldHeight;
      if (down) {
        this.$refs.actionImg.style.width =
          this.$refs.actionImg.offsetWidth * 0.9 + "px";
        this.$refs.actionImg.style.height =
          this.$refs.actionImg.offsetHeight * 0.9 + "px";
      } else {
        this.$refs.actionImg.style.width =
          this.$refs.actionImg.offsetWidth * 1.1 + "px";
        this.$refs.actionImg.style.height =
          this.$refs.actionImg.offsetHeight * 1.1 + "px";
      }
      let newWidth = this.$refs.actionImg.offsetWidth;
      let newHeight = this.$refs.actionImg.offsetHeight;
      //从鼠标位置开始缩放
      this.$refs.actionImg.style.left =
        oldLeft - scaleX * (newWidth - oldWidth) + "px";
      this.$refs.actionImg.style.top =
        oldTop - scaleY * (newHeight - oldHeight) + "px";
      //从图片中心开始缩放
      // this.$refs.actionImg.style.left =
      //   oldLeft - (newWidth - oldWidth) / 2 + "px";
      // this.$refs.actionImg.style.top =
      //   oldTop - (newHeight - oldHeight) / 2 + "px";
    },

    dragMove(e) {
      this.$refs.actionImg.style.transition = "";
      if (this.isDrag) {
        this.oDragObj.style.top = this.nTY + e.clientY - this.y + "px";
        this.oDragObj.style.left = this.nTX + e.clientX - this.x + "px";
        return false;
      }
    },

    initDrag(e) {
      let oDragHandle = e.target;
      let topElement = "HTML";
      while (
        oDragHandle.tagName != topElement &&
        oDragHandle.className != "imgAction"
      ) {
        oDragHandle = oDragHandle.parentNode;
      }
      if (oDragHandle.className == "imgAction") {
        this.isDrag = true;
        this.oDragObj = oDragHandle;
        this.nTY = parseInt(this.oDragObj.style.top + 0);
        this.y = e.clientY;
        this.nTX = parseInt(this.oDragObj.style.left + 0);
        this.x = e.clientX;
        document.onmousemove = this.dragMove;
        return false;
      }
    },
  },
  destroyed() {
    document.onmousemove = null;
  },
  mounted() {
    // this.changeHref();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .imgs-position {
    text-align: center;
    height: 500px;
    img {
      margin-top: 130px;
      width: 396px;
      height: 235px;
    }
  }
}
.meteorologyShow {
  position: relative;
  width: 100%;
  // width: 906px;
  overflow: auto;
  height: 100%;
  // border: 3px solid #eee;
  // border-width: 3px;
  // border-style: solid;
  &::-webkit-scrollbar {
    display: none;
  }
  .imgAction {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  img {
    user-select: none;
    -ms-user-select: none;
    margin-top: -6px;
    width: 90%;
    height: 100%;
    z-index: 999;
    // height: calc(1/3 * 100%);
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: move;
  }
  .toolbar {
    transition: all 0.2s ease;
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 25px;
    cursor: pointer;
    &:hover {
      color: #1798ee;
    }
  }
}
/deep/ .ivu-modal-body {
  padding: 0;
}
</style>